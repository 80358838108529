@import url(//fonts.googleapis.com/css?family=Ubuntu);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
    display: flex;
    flex-direction: column;
    font-family: Ubuntu;
    background-color: #FFFFFF;
    color: #000000;
    width: 100%;
    height: 95vh;
    min-width: 300px;
    min-height: 400px;
    margin: 0;
    background: linear-gradient(to bottom, #4CAF50, #89db8d) ;
    background-size: cover;
    background-position: center center;
}

.panel {
    max-width:  720px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-size: cover;
}

.center {
    justify-content: center;
}

.center-text {
    text-align: center;
    margin: auto;
}

.full-width {
    width: 100%;
}
.large-pad-top {
    padding-top: 50px;
}
.large-pad-bottom {
    padding-bottom: 50px;
}
.large-marg-top {
    margin-top: 50px;
}
.white-back {
    background-color: white;
}
.small-marg {
    margin-top: 10px;
    margin-bottom: 10px;
}
.med-pad-v {
    padding-top: 35px;
    padding-bottom: 35px;
}
.large-pad-h {
    padding-left: 45px;
    padding-right: 45px;
}
.btn {
    padding: 20px 50px;
    background-color:  rgba(29, 185, 84, 0.75);
    color: #FFFFFF;
    border-radius: 25px;
}

.btn:hover { 
    cursor: pointer;
}

.half-width {
    width: 50%;
}

.fix {
    flex-grow: 1;
}

.home-banner {
    height: 600px;
    display: flex;
    flex-direction: column;
}
.large-pad-v {
    padding-top: 50px;
    padding-bottom: 50px;
}
.blurry-back-white {
    background-color: rgba(255, 255, 255, 0.75);
}

.flex-table {
    display: flex; 
    flex-direction: row; 
    flex-wrap: wrap; 
    text-align: center;
}
.btn {
    padding: 20px 50px;
    background-color:  rgba(29, 185, 84, 0.75);
    color: #FFFFFF;
    border-radius: 25px;
}

.btn:hover { 
    cursor: pointer;
}

.half-width {
    width: 50%;
}
.cookie-banner {
    display:flex;
    flex-direction: row;
    margin: auto;
    position: absolute;
    padding: 10px;
    background-color: white;

    font-size: 14px;
    text-align: center;

    width: 80%;
    max-height: 75px;
    top: 33%;
    left:0;
    right:0;

    border-style: solid;
    border-radius: 20px;
    border-color: var(--green);
    border-width: 3px;
}


.cookie-back {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    width: 100%;
}

.cookie-close {
    text-align: right;
    max-width: 10px;
}

.banner-content {
    width: 95%;
}
.nav {
    background-color: var(--gray);
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
    height: 7vh;
  }
  
  .links {
    list-style: none;
    padding: 0;
  }

  .links li {
    display: inline;
  }
  
  .links a {
    color: white;
  }

 .links > .menu-btn {
    display: none;
  }
  
  .links .logo {
    height: 45px;
  }
  
  .hov .hide {
    display: none;
  }
  
  .hov:hover .hide, .hov:active .hide {
    display: inline-flex;
  }

  .logo {
    position: absolute;
    width: 100%;
    height: 25px;
    top: 0;
    left: 0;
    text-align: center;
  }

  .img {
    margin:auto;
    height: 50px;
    width: 50px;
    
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    position: absolute;
    left:0;
    right: 0;
  }
  
  /* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
  @media screen and (max-width: 600px) {
    .links > li {
      display: none;
      justify-content: center;
      align-items: center;
      zoom: 1; 
      padding: 0 5px;
    }
  
    .links > .menu-btn {
      display: flex;
    }
  }
  @media screen and (min-width: 599px) {
    .links > li {
      display: inline;
    }
  }
.footer {
    width: 100%;
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    background-color: var(--gray);
    color: #FFF;
    height: 5vh;
    justify-content: space-between;
}

a {
    text-decoration: none;
    color: #FFF;
    padding: 0;
    margin: 5px 10px;
}

.blank {
    flex-grow: 1;
}
:root {
    --green: #1DB954;
    --gray: #333;
}
.action-select {
    border-width: 2px;
    border-bottom-style: ridge;
    text-align: center;
}

#action {
    margin:auto;
    margin-bottom: 16px;
}

.body {
    height: 88vh;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    max-width: 720px;
    margin: auto;
}
.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #89db8d; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
    margin-top:20px;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }

.flex-table-item {
    flex: 1 0 26%; 
    margin: 5px;
    padding: 5px;
}

.flex-table-item a {
    color: black;
}

.album {
    display: flex;
    flex-direction: column;
    border-color: var(--green);
    border-width: 5px;
    border-style: solid;
    height: 160px;
    width: 160px;
    margin: auto;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    justify-content: center;
    align-items: center;
}

.blurry-back-white {
    background-color: rgba(255, 255, 255, 0.75);
}

.album-info {
    width: 100%;
    color: black;
    text-align: center;
}
.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #89db8d; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
    margin-top:20px;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }
.body {

}

.copy {
    margin: auto;
    padding: 20px 10px;
}
