.footer {
    width: 100%;
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    background-color: var(--gray);
    color: #FFF;
    height: 5vh;
    justify-content: space-between;
}

a {
    text-decoration: none;
    color: #FFF;
    padding: 0;
    margin: 5px 10px;
}

.blank {
    flex-grow: 1;
}