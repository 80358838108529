.container {
    display: flex;
    flex-direction: column;
    font-family: Ubuntu;
    background-color: #FFFFFF;
    color: #000000;
    width: 100%;
    height: 95vh;
    min-width: 300px;
    min-height: 400px;
    margin: 0;
    background: linear-gradient(to bottom, #4CAF50, #89db8d) ;
    background-size: cover;
    background-position: center center;
}

.panel {
    max-width:  720px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-size: cover;
}

.center {
    justify-content: center;
}

.center-text {
    text-align: center;
    margin: auto;
}

.full-width {
    width: 100%;
}
.large-pad-top {
    padding-top: 50px;
}
.large-pad-bottom {
    padding-bottom: 50px;
}
.large-marg-top {
    margin-top: 50px;
}
.white-back {
    background-color: white;
}
.small-marg {
    margin-top: 10px;
    margin-bottom: 10px;
}
.med-pad-v {
    padding-top: 35px;
    padding-bottom: 35px;
}
.large-pad-h {
    padding-left: 45px;
    padding-right: 45px;
}
.btn {
    padding: 20px 50px;
    background-color:  rgba(29, 185, 84, 0.75);
    color: #FFFFFF;
    border-radius: 25px;
}

.btn:hover { 
    cursor: pointer;
}

.half-width {
    width: 50%;
}

.fix {
    flex-grow: 1;
}

.home-banner {
    height: 600px;
    display: flex;
    flex-direction: column;
}
.large-pad-v {
    padding-top: 50px;
    padding-bottom: 50px;
}