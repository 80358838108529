.cookie-banner {
    display:flex;
    flex-direction: row;
    margin: auto;
    position: absolute;
    padding: 10px;
    background-color: white;

    font-size: 14px;
    text-align: center;

    width: 80%;
    max-height: 75px;
    top: 33%;
    left:0;
    right:0;

    border-style: solid;
    border-radius: 20px;
    border-color: var(--green);
    border-width: 3px;
}


.cookie-back {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    width: 100%;
}

.cookie-close {
    text-align: right;
    max-width: 10px;
}

.banner-content {
    width: 95%;
}