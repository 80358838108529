.nav {
    background-color: var(--gray);
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
    height: 7vh;
  }
  
  .links {
    list-style: none;
    padding: 0;
  }

  .links li {
    display: inline;
  }
  
  .links a {
    color: white;
  }

 .links > .menu-btn {
    display: none;
  }
  
  .links .logo {
    height: 45px;
  }
  
  .hov .hide {
    display: none;
  }
  
  .hov:hover .hide, .hov:active .hide {
    display: inline-flex;
  }

  .logo {
    position: absolute;
    width: 100%;
    height: 25px;
    top: 0;
    left: 0;
    text-align: center;
  }

  .img {
    margin:auto;
    height: 50px;
    width: 50px;
    
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    position: absolute;
    left:0;
    right: 0;
  }
  
  /* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
  @media screen and (max-width: 600px) {
    .links > li {
      display: none;
      justify-content: center;
      align-items: center;
      zoom: 1; 
      padding: 0 5px;
    }
  
    .links > .menu-btn {
      display: flex;
    }
  }
  @media screen and (min-width: 599px) {
    .links > li {
      display: inline;
    }
  }